export const times = ['12:00', '14:00', '16:30', '18:30', '20:00']

export const minAge = '8'
export const maxAge = '100'

export const countryCode = 'PL'
export const yearSelected = '2024'
export const API_KEY_DATA = '8DX8eEe67njS1lbThFsdSw==rQQNpQ8PYbPZBjrx'

export const URL_POST_FD = 'http://letsworkout.pl/submit'
